:root {
    // --theme-color: #b0c1b6;
    --theme-color: #FEC600;
    --red: #ff1940;
    --gray-main: #414042;
    --yellow: #FEC600;
    --green: #20CF6C; 
}

html[data-theme="green"] {
    --theme-color: #414042;
    // --theme-color: #FFDE17;
}
html[data-theme="yellow"] {
    --theme-color: #414042;
    // --theme-color: #FFDE17;
}