.footer_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #212121;
  margin-top: auto;
}

.menu_block {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 1190px;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 40px 0;

  @media screen and (min-width: 910px) and (max-width: 1710px) {
    max-width: 870px;
  }

  @media screen and (max-width: 910px) {
    max-width: 755px;
  }

  @media screen and (max-width: 915px) {
    width: auto;
    flex-wrap: wrap;
    padding: 40px 40px;
  }

  @media screen and (max-width: 725px) {
    flex-wrap: wrap;
    padding: 40px 16px;
  }

  @media screen and (max-width: 680px) {
    flex-wrap: wrap;
    padding: 20px 16px;
  }
}

.logo {
  @media screen and (min-width: 830px) and (max-width: 1710px) {
    svg {
      width: 208px;
      height: 75px;
    }
  }

  @media screen and (max-width: 830px) {
    svg {
      width: 293px;
    }
  }

  @media screen and (max-width: 680px) {
    width: 100%;

    svg {
      width: 256px;
      height: 131px;
    }
  }
}

.links_block {
  display: flex;
  flex-direction: row;
  gap: 3px;

  @media screen and (max-width: 915px) {
    padding-top: 40px;
    width: 100%;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
  }

  @media screen and (max-width: 830px) {
    padding-top: 40px;
    padding-bottom: 0;
    flex-direction: row;
    padding-left: 0;
    padding-right: 0;
  }

  @media screen and (max-width: 750px) {
    padding-top: 10px;
    padding-bottom: 10px;
    flex-direction: column;
    padding-left: 0;
    padding-right: 0;
  }
}

.link {
  color: white;
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-bold);
  text-transform: uppercase;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 16px;
  padding-bottom: 16px;

  @media screen and (min-width: 769px) and (max-width: 1710px) {
    font-size: var(--font-size-m);
  }

  @media screen and (max-width: 1710px) {
    padding-left: 26px;
    padding-right: 26px;
    padding-top: 16px;
    padding-bottom: 16px;
  }

  @media screen and (max-width: 830px) {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 16px;
    padding-bottom: 16px;
  }

  @media screen and (max-width: 750px) {
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 16px;
    padding-bottom: 16px;
  }

  a {
    color: white;
    text-decoration: none;
  }
}

.active_link {
  background: #616161;
  border-radius: 12px;
}

.link:hover {
  background: #616161;
  border-radius: 12px;
}

.donwload_block {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0 40px;
  border-top: 2px solid #363636;
  border-bottom: 2px solid #363636;

  @media screen and (max-width: 725px) {
    margin: 0 16px;
  }
}

.items_container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 60px 40px;

  @media screen and (min-width: 769px) and (max-width: 1770px) {
    padding: 50px 40px;
  }

  @media screen and (max-width: 1225px) {
    flex-direction: column;
    gap: 80px;
  }

  @media screen and (min-width: 680px) and (max-width: 830px) {
    padding: 50px 0;
  }

  @media screen and (max-width: 680px) {
    padding: 28px 0;
    gap: 28px;
  }

  @media screen and (min-width: 470px) and (max-width: 680px) {
    flex-direction: row;
    justify-content: center;
    gap: 50px;
  }

  p {
    color: #e0e0e0;
    font-size: var(--font-size-lxl);
    font-weight: var(--font-weight-bold);
    letter-spacing: 0.5px;

    @media screen and (min-width: 1490px) and (max-width: 1770px) {
      font-size: var(--font-size-mlxl);
    }

    @media screen and (min-width: 1360px) and (max-width: 1490px) {
      font-size: var(--font-size-sxl);
    }

    @media screen and (min-width: 1225px) and (max-width: 1360px) {
      font-size: var(--font-size-xl);
    }

    @media screen and (min-width: 680px) and (max-width: 1225px) {
      font-size: var(--font-size-msxl);
    }

    @media screen and (max-width: 680px) {
      font-size: var(--font-size-xl);
    }
  }
}

.donwload_item {
  display: flex;
  align-items: center;

  @media screen and (max-width: 680px) {
    flex-direction: column;
  }

  @media screen and (min-width: 470px) and (max-width: 1225px) {
    justify-content: space-between;
  }
}

.donwload_links {
  padding-left: 30px;
  display: flex;
  gap: 16px;

  @media screen and (min-width: 1225px) and (max-width: 1710px) {
    img {
      width: 140px;
    }
  }

  @media screen and (max-width: 1225px) {
    img {
      width: 175px;
    }
  }

  @media screen and (max-width: 680px) {
    padding-left: 0;
    flex-direction: column;
    padding-top: 28px;
  }
}

.copy_policy {
  text-align: center;
  font-size: var(--font-size-ml);
  font-weight: var(--font-weight-regular);
  letter-spacing: 0.5px;
  padding-top: 36px;
  padding-bottom: 24px;

  p {
    font-size: var(--font-size-ml);
    font-weight: var(--font-weight-regular);
    letter-spacing: 0.5px;
    color: #9e9e9e;

    @media screen and (min-width: 769px) and (max-width: 1770px) {
      font-size: var(--font-size-s);
    }
  }
}


.yellow_block_first {
  display: flex;
  background: var(--theme-color);
  padding: 0 24px;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  height: 74px;
  

  @media screen and (min-width: 1225px) and (max-width: 1710px) {
    height: 59px;
  }

  @media screen and (max-width: 470px) {
    height: 44px;
    padding: 0 16px;
    border-radius: 8px;
  }

  a {
    font-size: var(--font-size-xxl);
    font-weight: var(--font-weight-bold);
    color: #ffffff;
    text-decoration: none;

    @media screen and (min-width: 1225px) and (max-width: 1710px) {
      font-size: var(--font-size-mxl);
    }

    @media screen and (max-width: 470px) {
      font-size: var(--font-size-sxl);
    }
  }

  .phone_icon {
    @media screen and (min-width: 1225px) and (max-width: 1710px) {
      svg {
        width: 35px;
        height: 35px;
        margin-top: 4px;
      }
    }

    @media screen and (max-width: 470px) {
      svg {
        width: 24px;
        height: 24px;
        margin-top: 4px;
      }
    }
  }
}
