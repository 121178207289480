.features_container {
    display: flex;
    flex-direction: column;
    padding-top: 80px;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 190px;

    @media screen and (max-width: 1875px) {
        flex-direction: column;
    }

    @media screen and (min-width: 1375px) and (max-width: 1710px) {
        flex-direction: row;
        padding-bottom: 250px;
    }

    @media screen and (min-width: 1225px) and (max-width: 1375px) {
        flex-direction: column;
        padding-bottom: 250px;
    }

    @media screen and (max-width: 1225px) {
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 250px;
    }

    @media screen and (max-width: 1115px) {
        padding-top: 64px;
        padding-bottom: 96px;
    }

    @media screen and (max-width: 470px) {
        padding-top: 40px;
        text-align: center;
        align-items: center;
        padding-bottom: 76px;
    }
}

.main_text_4 {
    display: flex;
    color: #000;
    font-size: 40px;
    font-weight: 700;
    max-width: 370px;
    line-height: normal;

    @media screen and (min-width: 1225px) and (max-width: 1710px) {
        font-size: 28px;
        max-width: 300px;
    }

    @media screen and (min-width: 1225px) and (max-width: 1435px) {
        font-size: 28px;
        max-width: 270px;
    }

    @media screen and (max-width: 1225px) {
        font-size: 28px;
        max-width: 270px;
    }

    @media screen and (max-width: 1115px) {
        font-size: 40px;
        font-weight: 700;
        max-width: 370px;
    }

    @media screen and (max-width: 470px) {
        font-size: 24px;
        max-width: none;
    }
}

.features_block {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    padding-left: 100px;

    @media screen and (min-width: 1711px) {
        padding-top: 40px;
        padding-left: 0;
    }

/*     @media screen and (max-width: 1875px) {
        padding-left: 0;
        padding-top: 40px;
    } */

    @media screen and (min-width: 1435px) and (max-width: 1710px) {
        padding-top: 0;
        padding-left: 20px;
    }

    @media screen and (min-width: 1375px) and (max-width: 1435px) {
        padding-top: 0;
        padding-left: 0;
    }

    @media screen and (min-width: 1225px) and (max-width: 1375px) {
        padding-top: 40px;
        padding-left: 0;
    }

    @media screen and (max-width: 1225px) {
        padding-top: 40px;
        padding-left: 0;
    }

    @media screen and (max-width: 1115px) {
        flex-direction: column;
        padding-top: 40px;
        padding-left: 0;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 24px;
    }

    @media screen and (max-width: 470px) {
        flex-direction: column;
        text-align: center;
        align-items: center;
        justify-content: center;
        padding-left: 0;
        padding-top: 35px;
        gap: 35px;
    }
}

.item_block {
    display: flex;

    @media screen and (max-width: 470px) {
        flex-direction: column;
        text-align: center;
        align-items: center;
        justify-content: center;
    }
}

.icon_block {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 96px;
    height: 96px;
    border-radius: 100%;
    background: var(--theme-color);

    @media screen and (min-width: 769px) and (max-width: 1710px) {
        width: 67px;
        height: 67px;

        img {
            height: 32px;
        }
    }

    @media screen and (max-width: 1115px) {
        width: 96px;
        height: 96px;

        img {
            height: auto;
        }
    }
}

.text_block {
    display: flex;
    flex-direction: column;
    padding-left: 24px;
    justify-content: center;

    @media screen and (min-width: 769px) and (max-width: 1710px) {
        padding-left: 16px;
    }

    @media screen and (max-width: 1115px) {
        padding-left: 24px;
    }

    @media screen and (max-width: 470px) {
        padding-left: 0;
    }
}

.main_text {
    font-size: 24px;
    font-weight: 700;
    line-height: normal;

    @media screen and (min-width: 769px) and (max-width: 1710px) {
        font-size: 15px;
    }

    @media screen and (max-width: 1115px) {
        font-size: 24px;
    }

    @media screen and (max-width: 670px) {
        padding-top: 17px;
    }
}

.second_text {
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-top: 9px;
    max-width: 300px;

    @media screen and (min-width: 769px) and (max-width: 1710px) {
        font-size: 15px;
        max-width: 250px;
    }

    @media screen and (max-width: 1115px) {
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding-top: 5px;
        max-width: none;
    }

    @media screen and (max-width: 670px) {
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding-top: 5px;
        max-width: 330px;
    }

    @media screen and (max-width: 670px) {
        padding-top: 10px;
    }
}

.last_item {
    max-width: 240px;

    @media screen and (min-width: 769px) and (max-width: 1710px) {
        max-width: 175px;
    }

    @media screen and (max-width: 1115px) {
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding-top: 5px;
        max-width: none;
    }

    @media screen and (max-width: 670px) {
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding-top: 5px;
        max-width: 240px;
    }
}