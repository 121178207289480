.driver_details_page {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 8px;
  gap: 12px;
  padding-top: 40px;

  p {
    margin: 0px !important;
  }
}

.driver_main_block {
  display: flex;
  flex-direction: column;
  width: calc(100% - 100px);
  gap: 30px;
}

.drivers_data_title {
  color: black;
  font-size: 24px;
  font-weight: 700;
}

.drivers_info_block {
  display: flex;
  padding-top: 5px;
  gap: 24px;

  img {
    width: 300px;
    height: 300px;
    border-radius: 16px;
    object-fit: cover;
  }

  .drivers_photo_none {
    display: flex;
    width: 300px;
    height: 300px;
    align-items: center;
    justify-content: center;
    border-radius: 16px;
    background: #eeeeee;

    .drivers_circle_none_photo {
      display: flex;
      width: 80px;
      height: 80px;
      align-items: center;
      justify-content: center;
      background: #1976d2;
      border-radius: 100px;
    }
  }
}

.drivers_remark {
  display: flex;
  padding-top: 16px;
  align-items: center;
  justify-content: space-between;

  .drivers_add_remark {
    display: flex;
    align-items: center;
    cursor: pointer;

    p {
      font-size: 14px;
      font-weight: 500;
      color: #FEC600;
      text-decoration: underline;
      padding-left: 4px;
    }

    svg {
      width: 24px;
      height: 24px;
    }
  }

  .drivers_updated_remark {
    display: flex;
    align-items: center;
    cursor: pointer;

    p {
      font-size: 14px;
      font-weight: 500;
      color: #1db960;
      text-decoration: underline;
      padding-left: 4px;
    }

    svg {
      width: 24px;
      height: 24px;
    }
  }

  .drivers_edit_remark {
    display: flex;
    align-items: center;

    p {
      font-size: 14px;
      font-weight: 500;
      color: #ee392e;
      text-decoration: underline;
      padding-left: 4px;
    }

    svg {
      width: 24px;
      height: 24px;
    }
  }
}

.drivers_info_grounds {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
}

.drivers_info_ground {
  display: flex;
  width: 100%;
  gap: 20px;
}

.driver_input {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  flex: 1;
  gap: 7px;

  p {
    color: black;
    font-size: 14px;
  }

  span {
    color: #ee392e;
  }

  input {
    display: flex;
    width: 100%;
    color: black;
    font-size: 14px;
    padding-left: 12px;
    height: 52px;
    border-radius: 8px;
    border: 1px solid #e0e0e0;
    outline: none !important;
    cursor: pointer;

    &:focus,
    &:active {
      border: 1.5px solid #1976d2 !important;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
      outline: none !important;
    }
  }
}

.pointer_none {
  pointer-events: none;
}

.driver_license_block {
  display: flex;
  padding-top: 5px;
  gap: 20px;

  .driver_license_single_item {
    display: flex;
    width: 100%;
    flex-direction: column;

    img {
      width: 500px !important;
      height: 300px !important;
      border-radius: 16px;
      object-fit: cover;
      // border: 3px solid #1DB960;
    }

    .driver_license_block_none {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 500px !important;
      height: 300px !important;
      border-radius: 16px;
      background: #eeeeee;

      .driver_license_block_none_icon {
        display: flex;
        width: 80px;
        height: 80px;
        align-items: center;
        justify-content: center;
        background: #1976d2;
        border-radius: 100px;

        svg {
          margin-top: 2px;
          margin-left: 2px;
        }
      }
    }
  }
}

.driver_car_block {
  display: flex;
  flex-wrap: wrap;
  padding-top: 5px;
  padding-bottom: 20px;
  gap: 20px;

  .driver_car_single_item {
    display: flex;
    width: calc(33.33333% - 14px);
    cursor: pointer;

    img {
      width: 100%;
      max-height: 330px;
      border-radius: 16px;
      object-fit: cover;
      // border: 3px solid #1DB960;
    }
  }
}

.info-buttons {
  display: flex;
  padding-top: 20px;
  padding-bottom: 55px;
  justify-content: flex-end;
  width: 100%;
  gap: 20px;
  border-top: 1px solid #e0e0e0;

  button {
    outline: none !important;
  }

  .main-button {
    display: flex;
    height: 52px;
    align-items: center;
    border-radius: 8px;
    justify-content: center;
    border: 1px solid black;
    background: none;
    color: black;
    font-size: 16px;
    font-weight: 500;
    padding-left: 24px;
    padding-right: 24px;
    gap: 5px;

    path {
      stroke: black;
    }
  }

  .delete_btn {
    path {
      stroke: none !important;
      fill: black !important;
    }
  }

  .aprove-button {
    display: flex;
    height: 52px;
    align-items: center;
    border-radius: 8px;
    justify-content: center;
    background: #1db960;
    border: none;
    color: white;
    font-size: 16px;
    font-weight: 500;
    padding-left: 24px;
    padding-right: 24px;
    gap: 5px;

    path {
      stroke: white;
    }
  }
}

.border-buttons {
  display: flex;
  border-left: 1px solid #e0e0e0;
}

.pointer-events-block {
  padding-top: 15px;
  padding-bottom: 40px;
  pointer-events: none;
}

.block_with_padding_top {
  padding-top: 30px;
}

.block_with_padding_bottom {
  padding-bottom: 30px;
}

.go_back_btn {
  display: flex;
  align-items: center;
  gap: 5px;

  svg {
    width: 18px;
    height: 18px;
  }

  .go_back_text {
    font-size: 16px;
    color: black;
    font-weight: 500;
    cursor: pointer;
  }
}
