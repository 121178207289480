[data-theme="green"] {
  --theme-color: #20CF6C;

  --main-bg-color: #2c2d31;
  --inverted-bg-color: #ffffff;

  --default-bg-input: #eeeeee;

  --order-label-sum: #FEC600;
  --order-text-sum: #212121;
}
