[data-theme="yellow"] {
    --theme-color: #FEC600;

    --main-bg-color: #2c2d31;
    --inverted-bg-color: #ffffff;
  
    --default-bg-input: #eeeeee;
  
    --order-label-sum: #FEC600;
    --order-text-sum: #212121;
}